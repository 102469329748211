import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Oruga from "@oruga-ui/oruga-next";
import PrimeVue from "primevue/config";

// Styles
// import "primevue/resources/themes/saga-blue/theme.css";
// import "primevue/resources/primevue.min.css";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.css";
import "primeicons/primeicons.css";

import "@oruga-ui/oruga-next/dist/oruga.css";
import "@/style/style.scss";
import "@/style/icon-library.ts";
import "@/appension-style/appension-style.scss";

import * as constants from "@/constants";

// Components
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Menu from "primevue/menu";
import Dropdown from "primevue/dropdown";
import Listbox from "primevue/listbox";
import OverlayPanel from "primevue/overlaypanel";
import Button from "primevue/button";
import orugaConfig from "./oruga-config";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import ConfirmationService from "primevue/confirmationservice";
// import ConfirmPopup from "primevue/confirmpopup";
import ConfirmPopup from "@/components/ConfirmPopup.vue";
import Dialog from "primevue/dialog";
import ConfirmDialog from "primevue/confirmdialog";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import Tooltip from "primevue/tooltip";
import SelectButton from "primevue/selectbutton";
import InputNumber from "primevue/inputnumber";
import Tag from "primevue/tag";
import Message from "primevue/message";
import Paginator from "primevue/paginator";

import { DefaultApolloClient } from "@vue/apollo-composable";
import createApolloClient from "@/ApolloClient";
import axios from "axios";

import oidc from "@/composables/useOidc";
import Constants from "./models/Constants";
import i18n from "@/plugins/i18n";

axios.get(constants.CONFIG_URI).then(({ data }) => startApp(data));

async function startApp(constants: Constants) {
  oidc()
    .initialize(constants.authority, constants.clientId, router)
    .then(() => {
      createApp(App)
        .directive("tooltip", Tooltip)
        .provide(DefaultApolloClient, createApolloClient(constants.apiUrl))
        .use(router)
        .use(Oruga, orugaConfig)
        .use(PrimeVue)
        .use(ToastService)
        .use(ConfirmationService)
        .use(i18n)
        .component("ConfirmPopup", ConfirmPopup)
        .component("font-awesome-icon", FontAwesomeIcon)
        .component("DataTable", DataTable)
        .component("Column", Column)
        .component("Menu", Menu)
        .component("Dropdown", Dropdown)
        .component("OverlayPanel", OverlayPanel)
        .component("Listbox", Listbox)
        .component("Button", Button)
        .component("Toast", Toast)
        .component("Dialog", Dialog)
        .component("ConfirmDialog", ConfirmDialog)
        .component("InputText", InputText)
        .component("Checkbox", Checkbox)
        .component("SelectButton", SelectButton)
        .component("InputNumber", InputNumber)
        .component("Tag", Tag)
        .component("Message", Message)
        .component("Paginator", Paginator)
        .mount("#app");
    });
}
